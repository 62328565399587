html,
body {
  background: #faf9fa;
  margin: 0;
  padding: 0;
}

.media-lessThan-md {
  display: block;
}

.media-greaterThan-md {
  display: none;
}

.media-greaterThan-xl {
  display: none;
}

.layout-header.ant-layout-header,
.layout-container {
  padding-inline: 1rem;
}

.menu .ant-menu-item {
  margin: 0;
}

.nav-reverse {
  flex-direction: row-reverse;
}

.header-hamburger .ant-drawer-header {
  padding: 1rem;
}

.header-hamburger .ant-drawer-header-title {
  justify-content: flex-end;
}

.header-hamburger .ant-drawer-header-title > button {
  margin: 0;
}

.ant-btn.header-hamburger-btn {
  display: inline-block;
}

.ant-form-item.inquiry-submit,
.order-submit-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  margin: 0;
  z-index: 1;
  background: #faf9fa;
}

.order-submit-desktop {
  display: none;
}

.ant-typography.footer-title {
  position: relative;
  font-weight: 600;
}

.ant-typography.footer-title::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -6px;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 16px;
  background-color: #3487f2;
  border-radius: 24px;
}

.home-carousel-slide {
  max-height: 11.25rem;
}

.home-action-columns {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
  padding: 1rem;
}

.home-action-columns.media-greaterThan-xl {
  display: none;
}

@media only screen and (min-width: 576px) {
  .ant-form-item.inquiry-submit {
    position: unset;
    margin-top: 1.5rem;
    padding: 0;
    z-index: 0;
  }
}

@media only screen and (min-width: 768px) {
  .layout-header.ant-layout-header,
  .layout-container {
    padding-inline: 1.5rem;
  }

  .media-greaterThan-md {
    display: block;
  }

  .nav-reverse {
    flex-direction: row;
  }

  .media-lessThan-md,
  .ant-btn.media-lessThan-md {
    display: none;
  }

  .order-submit-mobile {
    position: unset;
    display: none;
  }

  .order-submit-desktop {
    display: block;
    margin-top: auto;
  }

  .home-carousel-slide {
    max-height: 15rem;
  }
}

@media only screen and (min-width: 1200px) {
  .media-greaterThan-xl {
    display: block;
  }

  .home-carousel-slide {
    max-height: 22.5rem;
  }

  .home-action-columns.media-greaterThan-xl {
    display: flex;
  }
}
@media (max-width: 768px) {
  .sider-container.collapsed {
    width: 80px; /* 收縮的寬度 */
  }

  .toggle-button {
    display: block; /* 顯示漢堡選單切換按鈕 */
  }

  .logo {
    display: none; /* 隱藏標誌 */
  }
}

.admin-high-status-row {
  background-color: #e6e6e6; /* 您选择的颜色 */
}

.admin-low-status-row {
  background-color: #62606085; /* 您选择的颜色 */
}

